$primary: #D42B29 !default;
$primary2: #171820 !default;
$secondary: #1f1e2e !default;
$tertiary: #b5b7da !default;

$greyish: #807f91 !default;
$grey: rgba(89, 88, 89, 1) !default;
$grey_sh1: rgba(89, 88, 89, 0.5) !default;
$black: #000000 !default;
$transparent: rgba(0, 0, 0, 0) !default;

$blue: #267dff !default;
$blue_sh1: #302d75 !default;
$blue_sh2: #3873e6 !default;
$blue_sh3: #000013 !default;
$blue_sh4: #ffffff !default;
$blue_sh5: #2a293c !default;
$blue_sh6: #272639 !default;
$blue_sh7: #525161 !default;
$blue_sh8: #111018 !default;
$blue_sh9: #131316 !default;
$blue_sh10: #a846e9 !default;
$overlay: #333146 !default;
$overlay2: #1a2e38 !default;
$dropdown: #23222b !default;
$dropdown_sh1: #2c2b38 !default;
$water: $blue !default;

$red: #fe4343 !default;
$red_sh1: #fc62ff !default;
$error: $red !default;
$danger: $red !default;
$success: #21d17a !default;
$success_sh1: #2cbc63 !default;
$success_sh2: #30a488 !default;
$success_sh3: #1bc156 !default;
$success_sh4: #35db70 !default;
$neonColor: $success !default;
$lightGreen: #c8decb !default;
$pink: #eeadfa !default;
$pink_sh1: #F694FF !default;
$pink_sh2: #FF79EA !default;
$yellow: #FFE7AA !default;
$yellow_sh1: #fbbe31 !default;
$warning: #ef773c !default;
$info: #39d0ff !default;
$info_sh2: #4FFFF4 !default;
$headerBorder: #424153 !default;
$purple: #8459ff !default;
$tooltip: #4b4a5b !default;

$white: #ffffff !default;
$white_sh1: #e1e1e1 !default;
$white_sh2: #bacbe4 !default;
$white_sh3: #a9a8b0 !default;
$offwhite: #f7f8f9 !default;
$offwhite_sh1: #fefefe !default;
$footer: $primary !default;
$footer_sh1: #1b1b1b !default;
$border: #c7cfd8 !default;
$border2: #2f3037 !default;
$hoverColor: $neonColor !default;
$activeColor: $warning !default;
$unsuccessful: #ff3569 !default;
$blackred: #ac001a !default;
$scrollBar: #45454d !default;

$black_sh1: #2e2d36 !default;
$black_sh2: lighten(
  $black_sh1,
  20%
) !default; /* color code == #666666 also (0,0,0,0.6) */
$black_sh3: lighten($black_sh1, 40%) !default; /* color code == #999999 */
$black_sh4: lighten($black_sh1, 60%) !default; /* color code == #cccccc */
$black_sh5: lighten($black_sh1, 41%) !default; /* color code == #9c9c9c */
$black_sh6: #252437 !default;
$black_sh7: #171724 !default;
$black_sh8: #07070a !default;
$black_sh9: #183d2d !default;
$black_sh10: #010012 !default;
$black_sh11: #33323b !default;
$black_sh12: #21202d !default;
$black_sh13: #242428 !default;

// Social Color(s)
$facebook: #3b5998 !default;
$twitter: #00aeef !default;
$pinterest: #bd081c !default;
$googleplus: #e34829 !default;
$youtube: #d62423 !default;
$vimeo: #5bc7ff !default;
$instagram: #2c5987 !default;
$linkedin: #1f7bc2 !default;
$flickr: #377ede !default;
$lightgrey: #cdcdcd !default;
$lightergrey: #8b8b90 !default;
$lightborder: #e0e0e0 !default;
$mobilemenu: #f8f9fa !default;

// Type Color(s)
$plant: $success !default;
$insect: #70ff00 !default;
$toxic: #ffbe0a !default;
$melee: #ffcbfa !default;
$earth: #d6f270 !default;
$metal: #84a2bd !default;
$drake: $pink !default;
$neutral: #d8faad !default;
$ice: #73cff7 !default;
$pixie: #df84ff !default;
$air: #73f7d7 !default;
$stone: #939997 !default;
$virus: #c1ff3d !default;
$cyber: #bbbfc1 !default;
$water: $blue !default;
$electric: $yellow !default;
$special: #d987f5 !default;
$evil: #ff5a5a !default;
$nondamaging: #9badb7 !default;
$flame: #ff9255 !default;
$physical: #f97800 !default;
$ghoul: #7390f7 !default;

/* Widths ▸ Window Width */
$viewportSize: 100%;
$max-width: 1920px;

// Dimensions

$screen-xs: 767px !default;

//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;

//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 991px !default;
$screen-sm-min: $screen-sm !default;

//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;

//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;

//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

/* Media Query Breakpoints */
$media-desktop: "screen and (max-width: 1199px)";
$media-ipadPortrait: "screen and (max-width :991px)";
$media-iPad: "screen and (min-width : 768px) and (max-width : 991px)";
$media-ipadSmall: "screen and (max-width :767px)";
$media-tablet: "screen and (max-width: 575px)";
$media-phone: "screen and (max-width : 480px)";
