/* Begin Modal Style */

:root {
  --bs-modal-klavio-width: 520px !important;
  --bs-backdrop-opacity: 0.7 !important;
}

.fade {
  transition: opacity 0.075s linear !important;
}

.offcanvas {
  @extend .backdrop-md;

  &-backdrop {
    &.show {
      @extend .backdrop-md;
    }
  }
  &-overlay {
    @extend .backdrop-md;
  }
}

.modal-klavio {
  max-width: var(--bs-modal-klavio-width);
}

.modal {
  align-content: center;

  &-backdrop {
    opacity: var(--bs-backdrop-opacity);
  }

  .bg-dark {
    background-color: $black !important;
  }

  &-content {
    box-shadow: 0 0 50px 10px rgba($black, 0.25);
  }

  &-dialog {
    padding: 0;
    @include transition(width 0.2s ease-in-out);
  }

  &-content {
    @include padding(0);
    border: 1px solid rgba($black, 1);
    background-color: rgba($white, 1);
    @extend .radius-sm;
    @extend .backdrop-md;

    &-wrapper {
      @extend .w-full;
    }
  }

  &-header,
  &-footer {
    border: 0 none !important;
  }

  &-header {
    color: $black;
    position: relative;
    @include padding(20);
    line-height: 32px;

    .modal-title {
      padding: 0;
      color: $black;
      @extend .fs-20;
      @extend .fw-bold;
      @extend .primary-font;
      line-height: 32px;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    .btn-close {
      top: 20px;
      right: 20px;
      color: $white;
      position: absolute;
      margin-top: 0 !important;
      margin-right: 0 !important;
      @include padding(4);
      @include size(32px);
      @include minSize(32px);
      box-sizing: border-box;
      @extend .radius-xs;
      @extend .border-0;
      background-color: $primary !important;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3E%3Cpath d='M11.8 13.2 8 9.4l-3.8 3.8-1.4-1.4L6.6 8 2.8 4.2l1.4-1.4L8 6.6l3.8-3.8 1.4 1.4L9.4 8l3.8 3.8z'/%3E%3C/svg%3E") !important;
      background-size: 18px 18px !important;
      flex-shrink: 0;
      @extend .spring;

      &:hover,
      &:active,
      &:focus {
        color: $white;
        background-color: lighten($primary, 2%) !important;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3E%3Cpath d='M11.8 13.2 8 9.4l-3.8 3.8-1.4-1.4L6.6 8 2.8 4.2l1.4-1.4L8 6.6l3.8-3.8 1.4 1.4L9.4 8l3.8 3.8z'/%3E%3C/svg%3E") !important;
      }

      &:active {
        @include transform(scale(0.95) !important);
        * {
          @include transform(scale(0.95) !important);
        }
      }

      @media (max-width: 767px) {
        top: 20px;
        right: 20px;
      }
    }
  }

  &-body {
    color: $black;
    position: relative;
    @include padding(20);
    @extend .fs-18;

    .klaviyo-form-SewzHR {
      form {
        padding: 0 !important;

        .needsclick {
          > div.needsclick {
            padding-left: 0 !important;
            padding-right: 0 !important;

            input {
              color: $black !important;
              outline: none !important;
              box-shadow: none !important;
              @extend .fs-18;
              line-height: 24px !important;
              @include padding(8 12 10 !important);
              background-color: $white !important;
              @extend .normalFont;
              @extend .fw-bold;
              @extend .radius-xs;
              @extend .easing;

              &[aria-invalid="false"] {
                border: 1px solid $border !important;

                &:not(.list-count select):focus {
                  border-color: $blue !important;
                  box-shadow: 0 0 0 0.15rem rgba($blue, 0.15) !important;
                }
              }

              &::placeholder {
                color: rgba($tertiary, 0.5) !important;
              }
              &::-moz-placeholder {
                color: rgba($tertiary, 0.5) !important;
              }

              &[aria-invalid="true"] {
                border-color: $danger !important;

                &:not(.list-count select):focus {
                  border-color: $danger !important;
                  box-shadow: 0 0 0 0.15rem rgba($danger, 0.15) !important;
                }
              }
            }

            button {
              padding: 0.25rem !important;
              padding-bottom: 0.375rem !important;
              border: 0 none !important;
              @extend .radius-xs;
              background-color: $primary !important;
              @extend .normalFont;
              @extend .fw-bold;
            }
          }
        }
      }
    }
  }
}

.animate__animated.animate__fast {
  animation-duration: calc(var(--animate-duration) * 0.25);
}
