/* Begin Home style */
:root {
  --padding: 1.25rem;
  --border-radius: 2rem;
}

.u-error {
  @extend .w-full;
  min-height: 260px;
  @extend .vc;

  p {
    font-size: 1.5rem;

    @media (max-width: 420px) {
      font-size: 6vw;
      letter-spacing: -0.025rem;
    }
  }
}

.link-view {
  color: $white;
  border-bottom: 1px solid currentColor;

  &:hover,
  &:focus,
  &:active {
    color: $hoverColor;
    border-bottom: 1px solid currentColor;
  }
}

.text-link {
  @extend .text-white-50;
  @extend .tertiaryFont;

  &:hover,
  &:focus,
  &:active {
    @extend .text-hover;
  }
}

.c-home,
.c-discover {
  @extend .w-full;
  position: relative;

  @media (max-width: 991px) {
    overflow-x: hidden;
  }
}

.c-home {
  flex: 1;
  @extend .w-full;
  @extend .u-flexed;
  @extend .u-flex-direction-column;

  &__logo {
    width: 300px;
    height: 90px;
    display: block;
    margin: 0 auto;
    color: $white;
    @include margin-bottom(50);

    @media (max-width: 576px) {
      @include margin-bottom(40);
    }

    &:hover, &:active {
      color: $white;
    }
  }

  &__wrap {
    flex: 1;
    @extend .z-5;
    position: relative;
    text-align: center;
    @include padding(20);
    text-align: center;
    @extend .w-full;
    @extend .u-flexed;
    @extend .u-align-center;
    @extend .u-flex-direction-column;
    justify-content: center;

    .u-viva__message {
      p {
        span {
          @extend .w-full;
          display: inline-block;
          font-size: 1.25rem;

          @media (max-width: 420px) {
            font-size: 5vw;
            letter-spacing: -0.025rem;
          }
        }
      }
    }
  }

  &__menu {
    margin: 0;
    padding: 0;
    @extend .w-full;
    list-style: none;
    @include margin-top(0);

    &__items {
      @extend .w-full;
      list-style: none;
      @include margin-bottom(8);

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__link {
      color: $white;
      font-size: 1.5rem;
      line-height: 1.5;
      text-align: center;
      @include padding(8 15);
      @include padding-bottom(11);
      border: 2px solid $white;
      @extend .fw-bold;
      background-color: rgba($white,0);
      @extend .vc;
      @extend .text-uppercase;

      &:hover, &:active, &:focus {
        color: $white;
        background-color: rgba($white,.1);
      }

      &.u-form-submit {
        @include padding(14);
        @include padding-bottom(18);
      }
    }
  }
}

// Checkbox
.form-check {
  &-label {
    @extend .fs-13;
    @extend .fw-medium;
    @extend .u-inline-flexed;
    @extend .u-align-center;
  }

  &-input {
    margin-top: 0;
    width: 1.125rem;
    height: 1.125rem;
    @include margin-right(10);
    border-color: $tertiary;
    background-color: $tertiary;
    @include border-radius(2px);

    &:checked {
      border-color: $info;
      background-color: $info;

      &[type="checkbox"] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
      }
    }

    &:focus {
      @include border-radius(2px);
      @include box-shadow(0 0 0 .25rem rgba($info,.15));
    }
  }
}

.c-home__wins {
  text-align: left;
  position: relative;
  @extend .z-5;
  @extend .secondary-font;
  @extend .fs-12;

  @media (max-width: 991px) {
    background: 0 0;
    backdrop-filter: inherit;
    padding: 0 16px;
    margin: 48px 0;
  }

  @media (min-width: 992px) {
    bottom: 0;
    left: 25px;
    position: absolute;
    padding: 24px 24px 16px;
  }

  &__gain {
    color: $success;
    margin-left: 0.125rem;
  }

  &__item {
    @extend .fs-12;
    @include padding(8 0);
    color: rgba($white,.95);
    @extend .fw-medium;
    @extend .text-uppercase;

    &:nth-of-type(3) {
      @include opacity(.6);
      border-top: 1px solid rgba($white,.15);
    }
    &:last-of-type {
      @include opacity(.3);
      border-top: 1px solid rgba($white,.3);
    }
  }

  &__title {
    @extend .fs-14;
    color: rgba($white,.95);
    @extend .fw-semibold;
    @extend .text-uppercase;
    margin-left: 0.75rem;
    line-height: 1.5;
  }
}

.c-login__wrapper {
  @extend .w-full;
}

// Social
.viva__social {
  @extend .w-full;
  position: relative;
  list-style: none;
  @extend .u-flexed;
  @extend .u-justify-center;
  gap: 0.5rem;

  &__link {
    color: $primary;
    @include size(40px);
    @extend .vc;
    @extend .radius-xl;
    background-color: $white;
    @extend .easing;

    &:hover {
      color: $white;
      background-color: $black;
    }
  }

  &__wrap {
    @extend .z-5;
    position: relative;
    @include padding(16 24 32);

    @media (max-width: 991px) {
      @include padding(16 16 32);
    }
  }

  &__filter {
    color: $white;
    @extend .w-full;
    @extend .u-flexed;
    position: relative;

    .btn-filter {
      color: $white;
      @include border-radius(20px);
      border: 1px solid $border2;
      background-color: $primary;
      padding: 0.5rem 1rem;

      &:hover {
        border-color: lighten($border2, 5%);
        background-color: rgba($primary2,.25);
      }

      .icon-arrow {
        @extend .easing;
      }

      &.is-opened {
        .icon-arrow {
          @include transform(rotate(180deg));
        }
      }
    }
  }
}

.c-filter {
  &__dropdown {
    left: 0;
    top: 100%;
    @extend .w-full;
    max-width: 300px;
    position: absolute;
    border: 1px solid $border2;
    background-color: $primary2;
    @include border-radius(0.75rem);

    &__header,
    &__body,
    &__footer {
      @include padding(20);
    }

    &__body,
    &__footer {
      font-size: 0.813rem;
      border-top: 1px solid $border2;
    }

    &__body {
      &__items {
        @extend .w-full;
        @extend .u-flexed;
        @extend .u-flex-direction-column;
        margin-bottom: 0 !important;
      }

      .hr {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
      }

      .form-control {
        border: 1px solid $border2;
        background-color: transparent;
        box-shadow: none;
        @extend .fs-14;
        color: $white;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        @include border-radius(20px);
      }
    }

    &__footer {
      @extend .u-flexed;
      @extend .u-justify-btw;

      .btn {
        @include border-radius(20px !important);

        &-small {
          @extend .fs-14;
          line-height: 1.35;
          padding: 0.375rem 1.25rem !important;
        }

        &-secendory {
          color: $success !important;
          padding: 0.375rem 0 !important;
          background-color: transparent !important;
        }
      }
    }
  }

  &__checkbox {
    @extend .w-full;
    margin-bottom: 0.25rem;

    .form-check-label {
      color: $white;
    }
  }
}

// Keyframes

@keyframes pulse {
  0% {
    @include transform(scale(0.75));
  }
  70% {
    @include transform(scale(1));
    @include box-shadow(0 0 0 12px rgba($success, 0));
  }
  100% {
    @include transform(scale(0.75));
    @include box-shadow(0 0 0 0 rgba($success, 0));
  }
}

@keyframes animation-tY10 {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes crescendo {
  0% {
    @include transform(scale(1));
  }
  100% {
    @include transform(scale(1.3));
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Toastify */
.Toastify__close-button {
  right: 0.675rem;
  position: absolute;
  @include size(24px);
  top: calc(50% - 15px);
  color: #919095;

  svg {
    @include size(18px);
  }
}
.Toastify__toast-container--top-left {
  top: 88px !important;

  @media (max-width: 767px) {
    top: 80px !important;
    left: 1rem !important;
    right: 1rem !important;
    min-width: calc(100% - 2rem) !important;
    border-radius: 0.425rem !important;
    overflow: hidden;
  }
}

.Toastify__toast {
  $toastifySelf: &;
  border-radius: 0.425rem !important;

  &-icon {
    top: 0.125rem;
    position: relative;
    margin-inline-end: 0.75rem !important;
  }

  &-container {
    padding: 0 !important;
    width: auto !important;
    min-width: 305px !important;
    max-width: 625px !important;
  }

  .toast-message {
    min-height: 24px;
    padding-right: 3.25rem;
  }

  #{$toastifySelf}-body {
    @extend .fs-13;
    line-height: 16px !important;
    @extend .fw-medium;
    @extend .normalFont;
    align-items: center !important;
    text-transform: none !important;
    padding-bottom: 0.75rem !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @extend .fs-16;
      @extend .fw-bold;
      @extend .primaryFont;
      margin-bottom: 0.25rem !important;

      &:last-of-type,
      &:only-of-type {
        margin-bottom: 0;
      }
    }

    p {
      @extend .fs-13;
      @extend .fw-medium;
      line-height: 1.35;
      color: $lightergrey;
      @extend .normalFont;
    }
  }

  #{$toastifySelf}-theme--dark {
    color: $white;
    background: $blue_sh8 !important;
    background-color: $blue_sh8 !important;
  }
  &-theme--dark {
    color: $white;
    background: $blue_sh8;
    background-color: $blue_sh8;
  }

  #{$toastifySelf}-theme--light {
    color: $primary;
  }
  &-theme--light {
    color: $primary;
  }

  > div[role="progressbar"] {
    border-radius: 0;
    margin: 15px 0 0 0;
    max-width: 100%;
  }

  &--success {
    #{$toastifySelf}-icon {
      svg {
        fill: $success;
      }
    }
  }

  &--error {
    #{$toastifySelf}-icon {
      svg {
        fill: $lightergrey;
      }
    }
  }

  @media (max-width: 767px) {
    &-container {
      padding: 0 !important;
    }
  }
}
.Toastify__progress-bar {
  border-radius: 0;

  &--success {
    background-color: $success;
  }

  &--error {
    background-color: $lightergrey;
  }
}

/* Web3modal */
w3m-modal {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 9999 !important;
  position: fixed !important;
  @include size(100% !important);
  background-color: rgba($black, 0.65) !important;
  @extend .backdrop-sm;
}
.web3modal-provider-name,
.web3modal-modal-card {
  font-family: $primaryFont !important;
}
.web3modal-provider-name {
  font-weight: $weightBold !important;
  font-family: $normalFont !important;
}
.web3modal-provider-description {
  font-size: 16px !important;
}

// Responsive
@media (max-width: 767px) {
  body {
    overflow-x: hidden !important;
  }
  .sm\:d-flex {
    display: flex !important;
  }
  .sm\:w-full {
    width: 100% !important;
  }
  .mobile-col-6,
  .sm\:w-half {
    width: 100% !important;
    max-width: 50% !important;
  }
  .sm\:w-half2 {
    width: 100% !important;
    max-width: 220px !important;
  }
  .mobile-col-4 {
    width: 100% !important;
    max-width: 33.3333% !important;
  }
  .sm\:flex-row {
    @include flex-direction(row, horizontal);
  }
  .sm\:flex-col {
    @include flex-direction(column, vertical);
  }
  .sm\:align-start {
    align-items: flex-start !important;
  }
  .sm\:justify-start {
    justify-content: flex-start !important;
  }
  .sm\:justify-btw {
    justify-content: space-between !important;
  }
  .sm\:mmb-1 {
    margin-bottom: 0.625rem !important;
  }
  .sm\:mmb-2 {
    margin-bottom: 1rem !important;
  }
  .sm\:mmb-20 {
    margin-bottom: 1.25rem !important;
  }
  .sm\:mmb-3 {
    margin-bottom: 1.5rem !important;
  }
  .sm\:mmb-5 {
    margin-bottom: 2rem !important;
  }
  .sm\:mmb-6 {
    margin-bottom: 3rem !important;
  }
  .sm\:mml-0 {
    margin-left: 0 !important;
  }
  .sm\:mmt-0 {
    margin-top: 0rem !important;
  }
  .sm\:mmt-1 {
    margin-top: 1rem !important;
  }
  .sm\:mmt-5 {
    margin-top: 1.5rem !important;
  }
  .sm\:mmt-6 {
    margin-top: 3.5rem !important;
  }
  .sm\:float-center,
  .sm\:text-center {
    float: none !important;
    text-align: center !important;
    justify-content: center !important;
  }
  .sm\:pt-0 {
    padding-top: 0 !important;
  }

  .sm\:pr-2 {
    padding-right: 40px !important;
  }

  .md\:flex-col {
    @include flex-direction(column, vertical);
  }
  .md\:ml-0px {
    margin-left: 0 !important;
  }
  .md\:nowrap {
    white-space: nowrap;
  }

  .sm-ms-auto {
    margin-left: auto !important;
  }

  .mobile-half {
    width: 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
    flex-shrink: 0 !important;
  }
  .mobile-third {
    width: 33.33333333% !important;
    flex: 0 0 33.33333333% !important;
    max-width: 33.33333333% !important;
    flex-shrink: 0 !important;
  }
  .mobile-text {
    @include font-size(16);
    line-height: 1.25;
  }
}

@media screen and (min-width: 577px) and (max-width: 767px) {
  .container {
    max-width: 96% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .md\:flex-col {
    @include flex-direction(column, vertical);
  }
  .md\:align-start {
    align-items: flex-start !important;
  }
  .md\:ml-0px {
    margin-left: 0 !important;
  }
  .md\:nowrap {
    white-space: nowrap;
  }
  .md\:w-half {
    width: 100% !important;
    max-width: 50% !important;
  }
  .md\:w-half2 {
    width: 100% !important;
    max-width: 220px !important;
  }
}

/* End Home style */
