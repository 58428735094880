/* Forms
------------------------------------*/
.required {
  color: $red;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.form-horizontal {

  .form-group {
    &.mb-3 {
      margin-bottom: 0.75rem !important;
    }

    &.mb-0 {
      margin-bottom: 0 !important;
    }
  }

  .form-label {
    width: 100%;
    text-align: left;
    color: $blue_sh9;
    @extend .fs-18;
    @extend .fw-bold;
    @include margin-bottom(4);
  }
  .form-text {
    text-align: left;
    color: $tertiary !important;
    @extend .fs-12;
    line-height: 1.4;
  }
  .form-select,
  .form-control {
    color: $black;
    outline: none;
    border: 1px solid $border;
    box-shadow: none;
    @extend .fs-18;
    line-height: 24px;
    @include padding(8 12 10);
    background-color: $white;
    @extend .normalFont;
    @extend .fw-bold;
    @extend .radius-xs;

    &.form-normal-text {
      @extend .normalFont;
    }
    &:not(.list-count select):focus {
      border-color: $blue;
      box-shadow: 0 0 0 0.15rem rgba($blue, 0.15);
    }

    &::placeholder {
      color: rgba($tertiary, 0.5);
    }
    &::-moz-placeholder {
      color: rgba($tertiary, 0.5);
    }

    &:invalid,
    &.is-invalid {
      border-color: $danger;

      &:not(.list-count select):focus {
        border-color: $danger;
        box-shadow: 0 0 0 0.15rem rgba($danger, 0.15);
      }
    }

    // &:valid,
    // &.is-valid {
    //   border-color: $success;

    //   &:not(.list-count select):focus {
    //     border-color: $success;
    //     box-shadow: 0 0 0 0.15rem rgba($success, 0.15);
    //   }
    // }

    &.is-valid {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M0 14V2C0 .9.9 0 2 0h12c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2z' style='fill:%231ed760;fill-opacity:.2'/%3E%3Cpath d='M7.2 10.9c-.2 0-.4-.1-.5-.2l-3-3 1.1-1.1 2.5 2.5 4-4 1.1 1.1-4.5 4.5c-.3.1-.5.2-.7.2z' style='fill:%231ed760'/%3E%3C/svg%3E") !important;
      background-position: calc(100% - 12px) center !important;
    }

    &.is-invalid {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M0 14V2C0 .9.9 0 2 0h12c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2z' style='fill:%23ec4a66;fill-opacity:.2'/%3E%3Cpath d='M11.1 6 10 4.9l-2 2-2-2L4.9 6l2 2-2 2L6 11.1l2-2 2 2 1.1-1.1-2-2z' style='fill:%23ec4a66'/%3E%3C/svg%3E") !important;
      background-position: calc(100% - 12px) center !important;
    }
  }
  .form-select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23807f91'%3E%3Cpath d='M8 10.4c-.2 0-.4-.1-.5-.2L3.9 6.7 5 5.6l3 3 3-3 1.1 1.1-3.5 3.5c-.2.1-.4.2-.6.2z'/%3E%3C/svg%3E") !important;
    background-position: calc(100% - 14px) center !important;
    background-repeat: no-repeat;
    background-size: 18px 18px;
  }

  .form-group {
    width: 100%;
    @extend .u-align-center;
    @extend .u-inline-flexed;
    @extend .u-justify-center;
    @extend .u-flex-direction-column;
    margin-bottom: 1rem !important;

    // &:last-of-type {
    //   margin-bottom: 0 !important;
    // }

    &-has-error {
      .form-control {
        border: 0 none !important;
        box-shadow: 0 0 0 3px rgba($red, 0.1) !important;

        &:focus {
          border: 0 none !important;
          box-shadow: 0 0 0 3px rgba($red, 0.25) !important;
        }
      }
      .help-block {
        color: $white;
        display: inline-block;
      }
      .text-lines {
        background-color: rgba($red, 0.25) !important;
      }
    }

    &-has-success {
      label {
        color: $success;
      }
      .form-control {
        border: 1px solid $success;
        box-shadow: 0 0 0 3px rgba($success, 0.1) !important;

        &:focus {
          border: 1px solid $success !important;
          box-shadow: 0 0 0 3px rgba($success, 0.25) !important;
        }
      }
      .help-block {
        color: $success;
      }
    }
  }

  .date-countdown {
    justify-content: space-between !important;
    .field-small {
      width: 43px;
      height: 48px;
      max-width: 43px;
      @include padding(12 12);
      background-color: $black_sh1;
    }
  }

  // Datepicker
  .react-datepicker {
    font-family: $primaryFont;
    border-color: $primary;
    background-color: $primary;
    @include box-shadow(0 12px 24px 5px rgba($black, 0.25));

    &__navigation-icon {
      &::before {
        border-color: $greyish;
        border-width: 2px 2px 0 0;
      }
    }

    &-wrapper {
      @extend .w-100;
    }
    &__input-container {
      @extend .w-100;

      .form-control {
        letter-spacing: 1px;
      }
    }
    &__header {
      border: 0 none;
      @include padding(12 0 6);
      background-color: $primary;
    }
    &__day,
    &__day-name,
    &__current-month {
      color: $white;
    }
    &__current-month {
      padding-bottom: 6px;
    }

    &__day {
      &--selected {
        background-color: $warning !important;
      }
      &:hover {
        background-color: $greyish !important;
      }
    }

    &-popper {
      &[data-placement^="top"],
      &[data-placement^="bottom"] {
        .react-datepicker__triangle {
          transform: translate3d(25px, 0px, 0px) !important;
        }
      }
      &[data-placement^="bottom"] {
        .react-datepicker__triangle {
          &::before,
          &::after {
            border-bottom-color: $primary;
          }
        }
      }
      &[data-placement^="top"] {
        .react-datepicker__triangle {
          &::before,
          &::after {
            border-top-color: $primary;
          }
        }
      }
    }
  }
}

.form-control[type="text"],
.form-control[type="search"],
.form-control[type="tel"],
.form-control[type="email"],
.form-control.is-invalid,
input[name="password"] {
  letter-spacing: 0 !important;
}

@media (max-width: 767px) {
  .form-group {
    [class^="col-"] {
      &:first-child,
      &:first-of-type {
        @include margin-bottom(20);
      }

      &:only-child,
      &:only-of-type,
      &:last-child,
      &:last-of-type {
        @include margin-bottom(0);
      }
    }
  }
}

.button-set {
  margin: 0;
  position: relative;

  label {
    margin: 0;
    padding: 0;
    color: $white;
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
    border: 1px solid currentColor;
  }

  &[data-theme="dark"] {
    label {
      width: 100%;
      height: 48px;
      max-height: 48px;
      background-color: $secondary;
      border: 1px solid $secondary;
      @include padding(10 15);
      @extend .radius-xs;
    }
  }
  input[type="radio"],
  input[type="checkbox"] {
    margin: 0;
    @include size(1px);
    padding: 0;
    @include opacity(0);
    position: absolute;
    pointer-events: none;

    & + span {
      padding: 0;
      @include size(18px);
      text-align: center;
      display: inline-block;
      background-color: $headerBorder;
      border: 1px solid $headerBorder;
      vertical-align: text-bottom;
      position: relative;
      @include margin-right(12);
      @extend .radius-xs;

      &::after {
        top: 0;
        left: 0;
        content: "";
        display: inline-block;
        @include opacity(0);
        @include size(100%);
        position: absolute;
      }
    }

    &:focus,
    &:active {
      + span {
        border-color: $success_sh2;
      }
    }

    &:checked {
      + span {
        &::after {
          @include opacity(1);
        }
      }
    }

    &.error {
      + span {
        border-color: $error;
      }
    }
  }
  input[type="radio"] {
    + span {
      top: 1px;
      @extend .radius-circle;

      &::after {
        @include size(100%);
        box-sizing: border-box;
        background-color: $success_sh2;
        border-color: $success_sh2;
        @extend .radius-circle;
      }
    }
    &:checked {
      + span {
        border: 1px solid $success_sh2;

        &::after {
          border: 2px solid $success_sh2;
        }
      }
      .check {
        &::before {
          -webkit-animation: rippleOn 500ms;
          -moz-animation: rippleOn 500ms;
          -o-animation: rippleOn 500ms;
          -ms-animation: rippleOn 500ms;
          animation: rippleOn 500ms;
          background-color: rgba($black, 0);
        }

        &::after {
          -webkit-animation: rippleOn 500ms forwards;
          -moz-animation: rippleOn 500ms forwards;
          -o-animation: rippleOn 500ms forwards;
          -ms-animation: rippleOn 500ms forwards;
          animation: rippleOn 500ms forwards;
        }
      }
    }
  }

  input[type="checkbox"] {
    + span {
      top: 0px;

      &::after {
        top: 10.5px;
        left: 2.5px;
        width: 63%;
        height: 35.5%;
        background-color: transparent;
        border: 0 solid $white;
        @include transform(rotate(-45deg) translate(50%, -75%));
      }
    }

    &:checked {
      + span {
        border: 1px solid $success_sh2;
        background-color: $success_sh2;

        &::after {
          border-left-width: 2px;
          border-bottom-width: 2px;
        }
      }
    }
  }

  &.small {
    input[type="radio"] {
      & + span {
        top: -2.5px;
        @include size(12px);
        background-color: $blue_sh2;
        border-color: $blue_sh2;
      }

      &:checked {
        + span {
          border: 1px solid $success_sh2;
        }
      }
    }

    &.yes {
      input[type="radio"] {
        & + span {
          background-color: $lightGreen;
          border-color: $lightGreen;
        }

        &:checked {
          + span {
            border: 1px solid $success_sh2;
          }
        }
      }
    }
  }
}

.button-set {
  .check-mark {
    top: 15px;
    right: 14px;
    @include size(16px);
    position: absolute;
    @include opacity(0);
    background-color: transparent;
  }
  input[type="radio"] {
    &:checked {
      ~ .check-mark {
        @include opacity(1);
      }
    }
  }

  &.is-selected {
    label {
      background-color: $blue_sh6;
      border: 1px solid $success_sh1;
    }
  }

  svg {
    @include size(16px);
    @extend .vc;
  }

  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;

    &.check {
      @include size(16px);
      @extend .vc;
      stroke-dashoffset: -100;
      -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
      animation: dash-check 0.9s 0.35s ease-in-out forwards;
    }
  }

  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
}

.modal-body {
  .button-set {
    &[data-theme="dark"] {
      label {
        background-color: $black_sh1;
        @extend .u-flexed;
        @extend .u-align-center;
      }
    }

    &.small {
      input[type="radio"] {
        + span {
          top: -0.5px;
        }
      }
    }
  }
  .progress {
    background-color: $black_sh1 !important;
    @extend .radius-sm;

    .check-mark {
      display: none;
      visibility: hidden;
      @include opacity(0);
      @include transform(scale(0));
      @extend .easingOut;
    }

    span {
      color: $white;
    }
    span:not(:first-of-type) {
      color: $tertiary;
    }

    &.is-selected {
      background-color: $blue_sh6 !important;
      @include box-shadow(inset 0 0 0 1px rgba($success_sh1, 1));

      .check-mark {
        display: flex;
        visibility: visible;
        @include opacity(1);
        @include transform(scale(1));
      }
    }
  }
}

.button-check {
  display: flex !important;
  align-items: center !important;
  min-height: inherit !important;
  padding-left: 1.5rem !important;
  margin-bottom: 1.25rem !important;
}

.form-check {
  position: relative;

  input[type="checkbox"] {
    @include size(16px);
    @include border-radius(2px);
    border-color: $success !important;
    background-color: $success !important;
    margin-top: 0 !important;
    margin-right: 0.5rem !important;
    @include transition(all 1000ms ease);

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    &:checked {
      border-color: $success !important;
      background-color: $success !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
      background-size: 16px 16px !important;
      @include transition(all 1000ms ease);
    }
  }
  &-label {
    font-size: 12px;
    color: $tertiary;
    @extend .pointer;
  }
}

.form-check-input-big {
  padding: 0;
  margin: 0 0.5rem;
  position: relative;
  width: 2.5rem; /* 40px */
  height: 1.5rem; /* 24px */
  border-color: $dropdown_sh1 !important;
  background-color: $dropdown_sh1 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%237f7f83'/%3e%3c/svg%3e") !important;

  &:checked {
    border-color: rgba($success, 0.1) !important;
    background-color: rgba($success, 0.3) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231ed760'/%3e%3c/svg%3e") !important;
  }
}

.error-message {
  margin-left: 0;
  @extend .w-100;
  @extend .fs-12;
  text-align: left;
  @extend .normalFont;
  @extend .fw-medium;
}

input[type="password"] {
  -webkit-text-security: square !important;
  letter-spacing: 2px;
  color: transparent;
}

.button-groups {
  @extend .u-flexed;

  .radius-lg {
    @extend .radius-xxl;
  }

  .btn-cancel,
  .btn-lockin {
    font-size: 14px;
    line-height: 18px;
    padding: 0.75rem !important;
    font-family: $primaryFont !important;
    @include transition(all 1000ms ease);
  }
  .btn-cancel {
    border: 1px solid rgba($white, 0.4);
    background-color: rgba($white, 0.3);

    &:hover,
    &:active {
      border: 1px solid rgba($white, 0.75);
      background-color: rgba($white, 0.5);
    }
  }

  .btn-lockin {
    color: $success;
    border: 1px solid rgba($success, 0.4);
    background-color: rgba($success, 0.3);

    &:hover,
    &:active {
      color: $white;
      border: 1px solid rgba($success, 0.75);
      background-color: rgba($success, 0.5);
    }
  }
}

// Switch
.form-switch {
  @extend .u-inline-flexed;
  @extend .u-align-start;

  .form-check-input {
    width: 2rem;
    height: 1.25rem;
    margin-top: 0.375em;
    flex-shrink: 0;
    border-color: $tertiary;
    background-color: $tertiary;

    &:disabled {
      opacity: 1 !important;
      cursor: not-allowed !important;
    }

    @extend .cursor-pointer;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23272639'/%3e%3c/svg%3e");

    &:focus {
      outline: none !important;
      box-shadow: none !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23272639'/%3e%3c/svg%3e");
    }
    &:checked {
      &:focus {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
      }
    }

    &:checked {
      &[color="success"] {
        border-color: $success_sh1;
        background-color: $success_sh1;
      }

      &[color="danger"] {
        border-color: $red;
        background-color: $red;
      }

      &[color="warning"] {
        border-color: $warning;
        background-color: $warning;
      }

      &[color="pink"] {
        border-color: $red_sh1;
        background-color: $red_sh1;
      }
    }
  }

  .form-check-label {
    color: $white;
    font-size: 15px;
    line-height: 1.5;
    @include padding-left(12);
    @extend .u-flexed;
    @extend .u-flex-direction-column;

    span {
      color: $tertiary;
      font-family: $normalFont;
    }
  }
}

.modal-body {
  .form-switch {
    .form-check-label {
      @include padding-left(32);
    }
  }
}

.form-group-otp {
  @extend .w-full;

  > div {
    @extend .u-flexed;
    @extend .u-flex-direction-row;
    gap: 6px;
    flex: 1;

    input {
      color: $white;
      outline: none;
      box-shadow: none;
      @extend .fs-18;
      line-height: 22px;
      @include padding(4 16);
      background-color: $border2;
      border: 1px solid $border2;
      @extend .normalFont;
      @extend .fw-semibold;
      @extend .radius-xs;
      @extend .w-full;
      max-width: 25%;
      height: 40px;

      &:focus {
        color: $success;
        border: 1px solid $success;
      }
    }
  }
}
.col-right {
  .form-group-pass {
    .form-control[type="password"].is-valid,
    .form-control[type="password"].is-invalid {
      background-position: calc(100% - 16px) center !important;
    }
  }
}

.form-group {
  &.mb-0 {
    margin-bottom: 0 !important;
  }
}

input[type="file"] {
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  color: $white;
  cursor: pointer;
  @include padding(8 12);
  border: 1px solid $border2;
  background-color: $border2;
  @extend .radius-xs;
  @extend .normalFont;
  @extend .fs-14;
  line-height: 20px;
  @extend .fw-semibold;
  @extend .u-flexed;
  @extend .u-align-center;

  &::file-selector-button {
    color: $white;
    border: 0 none;
    cursor: pointer;
    margin-right: 0;
    @include size(0px);
    @include padding(0);
    outline: none;
    background-color: transparent;
    @extend .radius-xs;
    transition: all 0.2s ease-in-out;
    font-size: 0;
  }
}

.drop-container {
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  position: relative;
  cursor: pointer;
  transition:
    all 0.2s ease-in-out,
    border 0.2s ease-in-out;
  @extend .u-flexed;
  @extend .u-align-center;

  .icon-upload {
    top: 12px;
    right: 12px;
    position: absolute;
    @extend .no-pointer;
  }

  &.sizeLg {
    height: 195px;
    min-height: 195px;
    max-height: 195px;

    input[type="file"] {
      height: 195px;
      min-height: 195px;
      max-height: 195px;
      border: 1px dashed rgba($white, 0.20);
    }
  }
}

.small-gap {
  .form-group {
    &.mb-3 {
      margin-bottom: 0.6rem !important;
    }
    &.mb-0 {
      margin-bottom: 0 !important;
    }
    &.mb-4 {
      margin-bottom: 1.5rem !important;
    }
  }

  div.form-control {
    min-height: 40px;
    max-height: inherit;
    @extend .u-flexed;
    @extend .u-align-center;
    line-height: 1.5 !important;
    color: $lightergrey !important;
    padding: 0.5rem 0.75rem !important;
    border: 0 none !important;
    padding-right: 0.75rem !important;
    font-size: 13px !important;
  }
}

.upload-btn-wrapper {
  width: 100%;
  height: 195px;
  overflow: hidden;
  position: relative;
  @extend .vc;

  .upload-preview {
    top: 0;
    left: 0;
    width: 100%;
    height: 195px;
    background-color: $red_sh1;
    @extend .radius-xs;
    position: relative;
    background-color: $border2;
    border: 1px dashed rgba($white, 0.20);
    @extend .vc;
    @extend .o-hidden;
    @extend .no-pointer;

    &::before {
      top: 0;
      left: 0;
      right: 0;
      content: " ";
      @include size(100%);
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba($border2, 0.1) 0%,
        rgba($border2, 0.25) 100%
      );
      @extend .z-1;
      @extend .radius-xs;
      @extend .no-pointer;
    }
  }

  .file-upload {
    width: 100%;
    height: 195px;
    position: absolute;
    padding: 1rem;
    @extend .radius-xs;
    @extend .z-2;
    // background-color: $border2;
    // border: 1px dashed rgba($white, 0.20);

    > span {
      color: $white;
      line-height: 20px;
      @extend .radius-xs;
      @include padding(8 16);
      @extend .u-inline-flexed;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #201F29;
      @extend .fs-12;
      @extend .fw-bold;
      @extend .normal-font;
    }
  }

  input[type=file] {
    top: 0;
    left: 0;
    @include opacity(0);
    position: absolute;
    height: 195px;
    min-height: 195px;
    max-height: 195px;
    @extend .z-4;
  }
}
