/* Begin Spinner style */

@keyframes spinner {
  to {
    @include transform(rotate(360deg));
  }
}

.spinner,
.spinner2,
.spinner3,
.spinner4,
.spinner5 {
  &::before {
    top: 50%;
    left: 50%;
    content: '';
    margin-top: -23px;
    margin-left: -23px;
    position: absolute;
    @include size(46px);
    box-sizing: border-box;
    @include border-radius(50%);

    @media (max-width: 767px) {
      margin-top: -15px;
      margin-left: -15px;
      @include size(30px);
    }
  }
}

.spinner {
  &::before {
    border: 3px solid $white;
    border-top-color: $hoverColor;
    animation: spinner 0.6s linear infinite;

    @media (max-width: 767px) {
      border-width: 2px;
    }
  }
}

.spinner2 {
  &::before {
    border: 3px solid $white;
    border-top-color: $hoverColor;
    animation: spinner 0.5s linear infinite;

    @media (max-width: 767px) {
      border-width: 2px;
    }
  }
}

.spinner3 {
  &::before {
    border: 3px solid transparent;
    border-top-color: $hoverColor;
    border-bottom-color: $hoverColor;
    animation: spinner 0.8s ease infinite;

    @media (max-width: 767px) {
      border-width: 2px;
    }
  }
}

.spinner4 {
  &::before {
    border-top: 4px solid $hoverColor;
    border-right: 4px solid transparent;
    animation: spinner 0.6s linear infinite;

    @media (max-width: 767px) {
      border-width: 3px;
    }
  }
}

.spinner5 {
  &::before {
    border: 3px solid $red;
    border-top-color: $success;
    border-right-color: $hoverColor;
    border-bottom-color: $warning;
    animation: spinner 0.5s linear infinite;

    @media (max-width: 767px) {
      border-width: 2px;
    }
  }
}

.u-page__loader {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $black;
  @extend .z-9;
  @extend .vc;

  .spinner4 {
    @include size(50px);

    &::before {
      margin-top: -25px;
      margin-left: -25px;
      @include size(50px);

      border-top: 5px solid $white;
      border-right: 5px solid transparent;
      animation: spinner 0.6s linear infinite;

      @media (max-width: 767px) {
        border-width: 4px;
      }
    }
  }
}

.loader {
    position: relative;
    @include size(100px);
  }
  .loader:before{
    left: 0;
    top: 50%;
    content: '';
    position: absolute;
    @include size(48px);
    @include border-radius(50%);
    @include transform(translate(-5px, -50%));
    background: linear-gradient(to right, $white 50%, $primary 50%) no-repeat;
    background-size: 200% auto;
    background-position: 100% 0;
    animation: colorBallMoveX 1.5s linear infinite alternate;
  }
 .loader:after{
    top: 0;
    left: 50%;
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    @include transform(translateX(-50%));
    background: $primary;
 }
@keyframes colorBallMoveX {
  0%  {
    background-position: 0% 0;
    @include transform(translate(-15px, -50%));
  }
  15%  , 25% {
    background-position: 0% 0;
    @include transform(translate(0, -50%));
  }
  75% , 85% {
    background-position: 100% 0;
    @include transform(translate(50px, -50%));
  }
  100% {
    background-position: 100% 0;
    @include transform(translate(65px, -50%));
  }
}


/* End Spinner style */
