/* Begin button style */

.btn,
.c-btn,
button {
  outline: none !important;
  box-shadow: none !important;
  user-select: none !important;
}

.c-btn {
  margin: 0;
  padding: 0;
  border: 0 none;
  background: 0 0;
  font-family: inherit;
  outline: none;
  text-align: inherit;
  line-height: inherit;
  cursor: pointer;
  color: $white;
  @extend .fs-18;
  @include padding(12 30 15);
  text-align: center;
  line-height: 1.25;
  @extend .radius-xs;
  text-transform: capitalize;
  @extend .fw-bold;
  background-color: $primary;
  @extend .u-inline-flexed;
  @extend .u-justify-center;
  @extend .u-align-center;
  @extend .pointer;

  &__block {
    @extend .w-full;
  }

  &:hover,
  &:active {
    background-color: darken($primary, 5%);
  }

  &:active {
    &:active {
      @include transform(scale(0.98) !important);
      * {
        @include transform(scale(0.98) !important);
      }
    }
  }
}

/* End button style */
