@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@font-face {
    font-family: 'CirkaVariable';
    src: url('../../fonts/CirkaVariable/CirkaVariable.eot');
    src: url('../../fonts/CirkaVariable/CirkaVariable.eot') format('embedded-opentype'),
         url('../../fonts/CirkaVariable/CirkaVariable.woff2') format('woff2'),
         url('../../fonts/CirkaVariable/CirkaVariable.woff') format('woff'),
         url('../../fonts/CirkaVariable/CirkaVariable.ttf') format('truetype'),
         url('../../fonts/CirkaVariable/CirkaVariable.svg#CirkaVariable') format('svg');
    font-display: swap;
    font-style: normal
}
