/* Begin utility view style */
.u-heading {
    &__title {
        @extend .fs-20;
        @extend .fw-semibold;
        @extend .text-white;
        @extend .text-uppercase;
    }
    &__excerpt {
        @extend .fs-14;
        @extend .text-tertiary;
        line-height: 1.5;
    }
}

.u-bg {
    &-white {
        background-color: $white;
    }
    &-offwhite{
        background-color: $offwhite;
    }
}
.u-page-info{
    $self: &; 
    &__title{
        @include font-size(20);
        font-weight: $weightBold;
        color: $black;
        z-index: 11;
        margin-bottom:19px;
        @include responsive (xs) {
            @include font-size(28);
        }
    }
    &__discription {
        p {
            @include font-size(13);
            color: $greyish;
            line-height:1.54;
            margin-bottom:18px;

        }
    }
}

.u-title {
    &--heading {
        color: $info;
        @include font-size(24);
        line-height: 1.15;
        font-weight: $weightBold;
        @include margin-bottom(5);

        @include responsive (xs) {
            @include font-size(40);
        }
    }
    
    &--subheading {
        color: $black;
        @include font-size(18);
        font-weight: $weightLight;

        @include responsive (xs) {
            @include font-size(32);
        }
    }
}

.u-sectionhead {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    width:$viewportSize;
    @include padding-bottom(9);
    border-bottom:1px rgba($greyish, 0.5) solid;
    &__name {
        @include font-size(30);
        font-weight: $weightLight;
      

        &--lightgrey {
            color:$greyish;
        }
    }
}

/* Begin utility view style */
